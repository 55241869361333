import React from 'react'
import _map from 'lodash/map'
import _get from 'lodash/get'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import utils from '../utils'

export const query = graphql`
query PharmaOSPageQuery {
	prismic {
		allPharma_oss {
			edges {
				node {
					...PharmaOSPageFragment
				}
			}
		}
	}
}
`

const PharmaOSPage = ({ data, location }) => {
	const doc = data.prismic.allPharma_oss.edges.slice(0, 1).pop()

	if (!doc) return null

	const title = doc.node.page_title
	const body = doc.node.body
	const noIndex = doc.node.no_index

	const Body = (body) => _map(body, (slice, index) => utils.sliceRenderer({ ...slice, key: index, pageTitle: title }))

	return (
		<Layout>
			<SEO
				title={doc.node.meta_title}
				description={doc.node.meta_description}
				keywords={doc.node.meta_keywords}
				image={doc.node.meta_image}
				url={_get(location, 'href')}
				meta={doc.node.meta}
				noIndex={noIndex} />
			<Body {...body} />
		</Layout>
	)
}

export default PharmaOSPage
